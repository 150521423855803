import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import CountUp from "react-countup";

import Layout from "../../components/layout";
import Header from "../../components/header";
import Tabs from "../../components/tabsWhoWeAre";

const Figures = () => {
  return (
    <Layout>
      <Header />
      <Tabs selection="cifras" />

      <main className="digits">
        <div>
          <StaticImage
            src="../../images/general/digits-banner.png"
            alt=""
            placeholder="blurred"
            layout="fixed"
          />
          <div className="content">
            <h2>Crecimiento sostenido</h2>
            <p>
              A pesar de las dificultades atravesadas por el sector durante los
              últimos años, Cenfarte ha crecido de forma constante desde 2002.
              Su posición en el mercado nacional de la distribución farmacéutica
              también se ha consolidado tal y como refleja el aumento de la
              cuota de socios que ha crecido un 85% durante el último periodo.
            </p>
          </div>
          <div className="numbers">
            <StaticQuery
              query={graphql`
                {
                  allNumbersJson {
                    edges {
                      node {
                        numbers {
                          name
                          number
                          prefix
                          suffix
                        }
                      }
                    }
                  }
                }
              `}
              render={(data) =>
                data.allNumbersJson.edges[0].node.numbers.map(
                  (value, index) => {
                    return (
                      <div key={index} className="number-container">
                        <div className="number">
                          <p>
                            <CountUp
                              start={0}
                              end={value.number}
                              duration={2}
                              separator="."
                              decimals={0}
                              decimal=","
                              prefix={value.prefix}
                              suffix={value.suffix}
                              delay={1}
                            />
                          </p>
                        </div>
                        <p className="name">{value.name}</p>
                      </div>
                    );
                  }
                )
              }
            />
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default Figures;
