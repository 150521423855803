import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import { formatURL } from "../utils/helpers";

const Header = (props) => {
  const [isToggledOn, setIsToggledOn] = useState(false);

  const handleClick = () => {
    setIsToggledOn(!isToggledOn);
  };

  const updateDimensions = () => {
    if (window.innerWidth <= 1200) {
      return;
    }

    setIsToggledOn(false);
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimensions);

    return () => {
      window.removeEventListener("resize", updateDimensions);
    };
  }, []);

  let banner;
  const location = props.location === undefined ? "general" : props.location;

  if (location === "/") {
    banner =
      props.bannerInfo.data.length === 0
        ? props.news.data[0]
        : props.bannerInfo.data[0];
  }

  return (
    <>
      <nav className="main-navigation">
        <div className="brand">
          <Link to="/">
            <StaticImage
              src="../images/general/logotipo.png"
              alt="Centro Farmacéutico del Norte"
              placeholder="blurred"
              layout="fixed"
              width={130}
            />
          </Link>
        </div>
        <button className="menu" onClick={handleClick}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
        <div className={isToggledOn ? "navigation expanded" : "navigation"}>
          <Link to="/nosotros/historia" activeClassName="selected">
            Nosotros
          </Link>
          <Link to="/servicios" activeClassName="selected">
            Servicios
          </Link>
          <Link to="/noticias" activeClassName="selected">
            Noticias
          </Link>
          <Link to="/eventos" activeClassName="selected">
            Eventos
          </Link>
          <Link to="/contacto" activeClassName="selected">
            Contacto
          </Link>

          <a
            href="https://clientes.cenfarte.es"
            target="_blank"
            rel="noopener noreferrer"
            className="btn-dashboard"
          >
            <div className="btn-clients">
              <div>
                <StaticImage
                  src="../images/dashboard_icon.png"
                  alt=""
                  placeholder="blurred"
                  layout="fixed"
                  width={14}
                />
              </div>
              <span>Acceso clientes</span>
            </div>
          </a>
        </div>
      </nav>
      {location === "general" ? (
        <header
          style={{ backgroundColor: `white`, height: 130, marginBottom: 10 }}
        />
      ) : (
        <header>
          <div id="main-banner">
            <div>
              <img src={banner.image} alt="Banner principal" />
            </div>
            <div>
              <h1>{banner.title}</h1>
              <p>{banner.description}</p>
              <Link
                to={`/noticia/${formatURL(banner.title)}`}
                className="btn-green"
                style={{ padding: "0 10px" }}
              >
                SEGUIR LEYENDO
              </Link>
            </div>
          </div>
        </header>
      )}
    </>
  );
};

export default Header;
