import React from "react";
import { Link } from "gatsby";

import twitter from "../images/general/icon_twitter.svg";
import linkedin from "../images/general/icon_linkedin.svg";
import facebook from "../images/general/icon_facebook.svg";

import { formatURL } from "../utils/helpers";

const Footer = (props) => {
  return (
    <footer>
      <div className="footer-info">
        {props.postsList ? (
          <div>
            <h5>Noticias</h5>
            {props.postsList.data.slice(0, 3).map((post, index) => {
              let postDate = new Date(post.date).toLocaleDateString("es-ES", {
                year: "numeric",
                month: "long",
                day: "numeric",
              });
              return (
                <Link to={`/noticia/${formatURL(post.title)}`} key={index}>
                  {post.title}
                  <time dateTime="2008-02-14">{postDate}</time>
                </Link>
              );
            })}
          </div>
        ) : (
          ""
        )}

        <div className={props.postsList ? "" : "footer-full"}>
          <p>
            <strong>
              Copyright © Cenfarte, {new Date().getFullYear()}. España.
            </strong>
          </p>
          <p>Todos los derechos reservados.</p>
          <Link to="/aviso-legal" className="enlace">
            Aviso legal
          </Link>
          <Link to="/cookies">Cookies</Link>
          <a
            href="https://twitter.com/cenfarte?lang=en"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            <img src={twitter} height="35" alt="Icono Twitter" />
          </a>
          <a
            href="https://www.linkedin.com/company/cenfarte"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            <img src={linkedin} height="35" alt="Icono Linkedin" />
          </a>{" "}
          <a
            href="https://es-es.facebook.com/cenfarte/"
            target="_blank"
            rel="noopener noreferrer"
          >
            {" "}
            <img src={facebook} height="35" alt="Icono Facebook" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
