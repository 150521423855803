export const formatURL = (url) => {
  return url
    .trimLeft()
    .trimRight()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/ /g, "-")
    .toLowerCase();
};

export { formatURL as default };
